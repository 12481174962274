@use '../../styles/partials/mixins' as *;

.skill {
    --gap: 1rem;
    position: relative;
    display: flex;
    overflow: hidden;
    user-select: none;
    gap: var(--gap);

    @include tablet {
        --gap: 2.5rem
    }

    &__list {
        flex-shrink: 0;
        display: flex;
        justify-content: space-around;
        gap: var(--gap);
        min-width: 100%;
        animation: scroll 30s linear infinite;
    }

    &__item {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        font-style: italic;
        color: #808080;
        font-size: 24px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 100%;
        -webkit-font-smoothing: antialiased;

        @include tablet {
            font-size: 48px;
        }
    }
}

@keyframes scroll {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(calc(-100% - var(--gap)));
    }
}