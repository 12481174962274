@use '../../styles/partials/mixins' as *;
@use '../../styles/partials/variables' as *;

.description {
    border: 1px solid #808080;
    height: 100%;


    &__toolbar-container {
        display: flex;
        justify-content: space-between;
        padding: 10px 1rem;
        border-bottom: 1px solid #808080;
        background-color: #333;

        @include tablet {
            padding: 1rem;
        }
    }

    &__project-name {
        color: $color-white;
        font-size: 14px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__icon {
        width: 14px;
        cursor: pointer;

        @include tablet {
            width: 16px;
        }
    }

    &__detail-container {
        display: flex;
        flex-direction: column;
        padding: 1rem;

        @include tablet {
            padding: 24px 16px 40px 16px;
        }
    }

    &__logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__logo {
        height: 40px;
    }

    &__name {
        color: $color-white;
        font-size: 22px;

        @include tablet {
            font-size: 24px;
        }
    }

    &__title {
        color: #D3D3D3;
        font-size: 18px;
        margin: 1rem 0;
        font-weight: 600;

        @include tablet {
            font-size: 20px;
        }

    }

    &__sub-title {
        color: #D3D3D3;
        font-size: 16px;
        font-weight: 600;

        @include tablet {
            font-size: 18px;
        }
    }

    &__detail {
        color: #CCCCCC;
        font-size: 14px;
        line-height: 18px;

        @include tablet {
            font-size: 16px;
            line-height: 20px;
        }

    }

    &__list {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: .5rem;
        flex-wrap: wrap;

        @include tablet {
            flex-wrap: nowrap;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        gap: 4px;
        flex-wrap: wrap;
    }

    &__link {
        color: #5295e2;
        text-decoration: underline;
    }
}