@use './variables' as *;
@use './mixins' as *;

// FONT FACES

// @font-face {
//     font-family: 'Inconsolata';
//     src: url('../../assets/fonts/Inconsolata-Regular.ttf');
//     font-weight: 400;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inconsolata';
//     src: url('../../assets/fonts/Inconsolata-SemiBold.ttf');
//     font-weight: 600;
//     font-display: swap;
// }

// @font-face {
//     font-family: 'Inconsolata';
//     src: url('../../assets/fonts/Inconsolata-Bold.ttf');
//     font-weight: 700;
//     font-display: swap;
// }


@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@400;600;700&display=swap');
// SECTION HEADING

.section-heading {
    color: $color-white;
    font-size: 24px;
    font-weight: 700;
    text-align: center;

    @include tablet {
        font-size: 32px;
    }
}