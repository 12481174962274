@use '../../styles/partials/mixins' as *;

.about {
    color: #fff;
    font-family: Inconsolata, monospace;
    padding: 24px 32px;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include tablet {
        padding: 32px 36px;
        height: 75vh;
        justify-content: center;
    }

    @include desktop {
        padding: 40px 12vw;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        @include tablet {
            flex-direction: row-reverse;
            align-items: center;
            gap: 3rem;
        }
    }


    &__text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 2rem;
        max-width: 100%;

        @include tablet {
            max-width: 50%;
        }
    }

    &__content {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        @include tablet {
            font-size: 18px;
            line-height: 24px;
        }
    }
}

.json {
    background-color: #2E333F;
    padding: 1rem;
    width: 100%;

    &__line {
        border-bottom: 1px solid #808080;
        padding-left: 3rem;

        @include tablet {
            font-size: 16px;
            line-height: 22px;
        }
    }

    &__index {
        margin-left: 3rem;
        color: #CE8349;


    }

    &__const {
        color: #23b6d8;

    }

    &__bracket {
        color: #CE70D6;
    }
}