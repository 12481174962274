@use '../../styles/partials/mixins' as *;

.banner {
    height: 120vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 2rem;
    margin-top: 10rem;
    gap: 5rem;

    @include tablet {
        height: 95vh;
        flex-direction: row;
        justify-content: space-around;
        gap: 2rem;
        padding: 24px 36px;
        margin: 0;
    }

    @include desktop {
        padding: 48px 12vw;
    }

    &::after {}

    &__text-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
    }

    &__heading-container {}

    &__heading {
        font-size: 36px;
        font-weight: 700;
        line-height: 70px;
        color: #fff;
        line-height: 42px;

        @include tablet {
            font-size: 48px;
            line-height: 54px;
        }
    }

    &__sub-heading {
        font-size: 14px;
        font-weight: 400;
        color: #fff;

        @include tablet {
            font-size: 16px;
        }
    }

    &__icon-container {
        display: flex;
        align-items: center;
        gap: 1rem;
    }

    &__link {
        background-color: #404453;
        padding: .5rem 1rem;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        gap: 4px;
        border: 1px solid #808080;
        font-size: 13px;

        @include tablet {
            font-size: 16px;
        }
    }

    &__icon {
        width: 20px;
        height: 20px;

        @include tablet {
            width: 24px;
            height: 24px;
        }
    }

}