@use '../../styles/partials/variables' as *;
@use '../../styles//partials//mixins' as *;

.footer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: .5rem;
    padding: 1rem;

    &__text {
        color: $color-white;
        font-size: 13px;

    }

    &__icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1rem;
    }

    &__link {}

    &__icon {
        width: 20px;
        height: 20px;

        @include tablet {
            width: 24px;
            height: 24px;
        }
    }
}