@use '../../styles/partials/mixins' as *;

.header-section {
    position: fixed;
    top: 0;
    width: 100%;
    padding: 1rem;
    z-index: 100000;
}

.header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    background-color: #1E1E1E;
    border: 1px solid #808080;
    border-radius: 12px;

    @include tablet {
        flex-direction: row;
        align-items: center;
        padding: 1rem 1.25rem;
    }

    @include desktop {
        padding: 1.5rem calc(12vw - 1rem);
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        color: #23b6d8;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1px;

        @include tablet {
            font-size: 22px;
        }
    }

    &__menu {
        position: relative;




        &,
        &::before,
        &::after {
            width: 1.5rem;
            height: 2px;
            background-color: #ffffff;
            display: inline-block;
            transition: all .2s;

            @include tablet {
                display: none;
            }
        }

        &::before,
        &::after {
            content: "";
            position: absolute;
            left: 0;
        }

        &::before {
            top: -.5rem;
        }

        &::after {
            top: .5rem;
        }

        &--close {
            background-color: transparent;

            &::before {
                top: 0;
                transform: rotate(135deg);
            }

            &::after {
                top: 0;
                transform: rotate(-135deg);
            }
        }
    }

}

.navbar {
    display: none;

    @include tablet {
        display: flex;
    }

    &__list {
        padding: 2rem 0 0;
        list-style: none;
        width: 100%;

        @include tablet {
            display: flex;
            padding: 0;
            width: auto;
            gap: 1.8rem;
            justify-content: center;
            align-items: center;
        }

        @include desktop {
            gap: 2rem;
        }
    }

    &__item {
        width: 100%;
        text-align: center;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        @include tablet {
            margin: 0;
            width: auto;
        }
    }

    &__link {
        font-size: 14px;
        font-weight: 600;
        color: #ffffff;
        cursor: pointer;

        @include tablet {
            font-size: 18px;
        }

        &--download {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: transparent;
            border: none;
            color: #23b6d8;
            gap: 4px;
        }
    }

    &__icon {
        width: 20px;

        @include tablet {
            width: 24px;
        }
    }

    &--close {
        display: flex;
        height: 100%;
        overflow: hidden;
    }
}