@use './typography' as *;

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    box-sizing: border-box;
}

body {
    font-family: 'Inconsolata', monospace;
    font-weight: 400;
    background: #1E1E1E;
}

a,
a:link,
a:visited {
    text-decoration: none;
}

ul {
    list-style: none;
}