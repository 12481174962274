@use '../../styles/partials/mixins' as *;

.terminal {

    border: 1px solid #808080;
    background: #404453;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 50vh;

    @include tablet {
        height: 45vh;
    }

    &__toolbar-container {
        border-bottom: 1px solid #808080;
        background: #333;
        display: flex;
        padding: 10px 1rem;
        justify-content: space-between;

        @include tablet {
            padding: 1rem;
        }
    }

    &__heading {
        text-transform: uppercase;
        color: #fff;
        font-size: 14px;
        font-weight: 600;

        @include tablet {
            font-size: 16px;
        }
    }

    &__icon {
        width: 14px;

        @include tablet {
            width: 18px;
        }
    }

    &__area {
        color: #fff;
        padding: 1rem;
        overflow-x: hidden;
        position: relative;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__command {
        position: absolute;
        left: -100000rem;
    }

    &__input {}

    &__liner {
        animation: show 0.5s ease forwards;
        opacity: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        font-size: 13px;

        @include tablet {
            font-size: 16px;
        }

        &::before {
            content: "Unknown-0perator$";
            color: #00ff00;
        }
    }

    &__typer {}

    &__cursor {
        position: relative;
        font-weight: 700;
        color: #00ff00;
        animation: blinker 1s linear infinite;
    }
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

@keyframes show {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.terminal-text {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;

    &__banner {
        font-size: 14px;
        line-height: 18px;
        color: #F5F5F5;
        font-weight: 400;

        @include tablet {
            font-size: 16px;
            line-height: 20px;
        }

        &--bold {
            font-weight: 600;
        }

        &--link {
            font-weight: 600;
            color: #23b6d8;
            cursor: pointer;
        }
    }


    @include tablet {
        font-size: 16px;
    }

    &__command {
        width: 30%;

        &--bold {
            font-weight: 600;
        }
    }

    &__description {
        color: #CCCCCC;
    }
}